import _ from 'lodash'
import React, { useState, useMemo } from 'react'
import { useOrderMethod } from 'react-omnitech-api'
import { useTranslation } from 'react-i18next'
import HomeView from './home-view'
import {
  useThemeConfig,
} from '../../hook'

function HomeController() {
  // prepare hook
  const { t } = useTranslation()
  const { getConfig, getContentGroup, selectableOrderMethod } = useThemeConfig()
  const {
    orderMethod,
  } = useOrderMethod()

  // internal states
  const [pageReady] = useState(true)

  // local variable
  const seoTitle = t('screens.home.seo.title')

  const homePageContentGroupCode = useMemo(() => getContentGroup(
    `config.pages.homePage.${_.get(orderMethod, 'code', 'default')}.contentGroup`,
    // fallback to default if no orderMethod specific contentGroup in theme config
    getContentGroup(
      'config.pages.homePage.default.contentGroup',
      getContentGroup(
        'config.pages.homePage.contentGroup',
        'ecom_home_page',
      ),
    ),
  ), [getContentGroup, orderMethod])

  const homePageContentGroupTemplate = useMemo(() => getConfig('config.pages.homePage.template', 'ContentGroup'), [getConfig])

  const viewProps = {
    homePageContentGroupCode,
    homePageContentGroupTemplate,
    pageReady,
    selectableOrderMethod,
    seoTitle,
  }

  return (
    <HomeView {...viewProps} />
  )
}

export default HomeController
